/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)


// import '@symfony/ux-dropzone/src/style.css'

import * as bootstrap from 'bootstrap'

import daterangepicker from 'daterangepicker';



// import './styles/hyper/scss/app-saas.scss';
import './styles/hyper/scss/app-modern.scss';
import './styles/hyper/scss/icons.scss';

import 'admin-resources/jquery.vectormap/jquery-jvectormap-1.2.2.min.js';
import 'admin-resources/jquery.vectormap/maps/jquery-jvectormap-world-mill-en.js';

import 'dropzone/dist/min/dropzone.min.js';
import './styles/hyper/js/ui/component.fileupload.js';


import './styles/hyper/js/hyper-config.js';
import './styles/hyper/js/hyper-layout.js';
import './styles/hyper/js/hyper-syntax.js';
import './styles/hyper/js/hyper-main.js';



// start the Stimulus application
import './bootstrap';

/* Make autoselect use bootstrap */
import 'tom-select/dist/css/tom-select.bootstrap5.css';


// import './styles/hyper/js/pages/demo.dashboard.js';







